export class ApplicationSettings {
  app: string;
  onesosuiEndpoint: string;
  googleAPIKey: string;
  isProduction: boolean;
  isTest: boolean;

  constructor() {
    this.onesosuiEndpoint = '';
  }
}
