import { Injectable } from '@angular/core';
import { SettingsLoaderService } from '@cupoui/core/services/settings.loader.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  constructor(private settingsLoaderService: SettingsLoaderService) {}

  getApp(): string {
    return this.settingsLoaderService.configuration.app;
  }

  getOnesosuiEndpoint(): string {
    return this.settingsLoaderService.configuration.onesosuiEndpoint;
  }

  isProduction(): boolean {
    return this.settingsLoaderService.configuration.isProduction;
  }

  isTest(): boolean {
    return this.settingsLoaderService.configuration.isTest;
  }

  isLocal(): boolean {
    return !this.isProduction() && !this.isTest();
  }

  getGoogleAPIKey() : string {
    return this.settingsLoaderService.configuration.googleAPIKey;
  }
}
